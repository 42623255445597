<template>
    <modal ref="modalCedisAsociados" titulo="Cedis Asociados" :cargado-modal="cargando" icon="bodega" no-aceptar adicional="Guardar" @adicional="crearCedis">
        <div class="row mx-0 mb-3">
            <div class="col-12 text-center text-general f-15">
                Selecciona los cedis a los cual se <br />
                asociará este proveedor
            </div>
        </div>
        <div v-for="(data,d) in cedis" :key="d" class="col-8 px-4 mb-3 mt-2">
            <el-checkbox v-model="data.checked" class="check-red">
                {{ data.nombre }}
            </el-checkbox>
        </div>
    </modal>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores";
export default {
    data(){
        return {
            cargando: false,
            cedis: [],
        }
    },
    methods: {
        toggle(cedis){
            this.cedis = _.cloneDeep(cedis)
            this.$refs.modalCedisAsociados.toggle();
        },
        async crearCedis(){
            try {

                let cedis = this.cedis.filter(d => d.checked).map(({id}) => id)
                let params = {
                    cedis,
                    id_proveedor: this.$route.params.id_proveedor
                }
                this.cargando = true
                const {data} = await Proveedor.crearCedis(params)
                this.notificacion('', data.mensaje, 'success')
                this.$emit('listar')
                this.$refs.modalCedisAsociados.toggle()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
